(() => {
    const alertBanner = document.querySelector('.js-alert_banner');
    const alertBannerClose = document.querySelector('.js-alert_banner__close');

    if (alertBanner) {
        alertBannerClose.addEventListener('click', (e) => {
            closeBanner(alertBanner);
        });

        if (!Cookies.get('banner'+alertBanner.dataset.bannerId)) {
            alertBanner.style.display = 'block';
        };
    }

    /**
     * Dismisses the cookie banner
     * @param {*} banner 
     */
    function closeBanner(banner) {
        banner.remove();
        const alertId = banner.dataset.bannerId;
        setAlertDismissedCookie(alertId);
        stickyHeader();
    }

    /**
     * Adds the alertID to the dismissed-banners cookie if it exists, or creates it if it doesn't exist
     * @param {*} alertId 
     */
    function setAlertDismissedCookie(alertId) {
        Cookies.set('banner'+alertId, 'dismissed', { expires: 30 });
    }
})();